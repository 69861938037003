export default [
  //JASA
  {
    path: '/kelas',
    name: 'route-kelas',
    component: () => import('@/views/pages/heykama/kelas/List.vue'),
    meta: {
      module: 'Class',
      pageTitle: 'Class',
      authRequired: true,
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/kelas/detail/:id',
  //   name: 'route-kelas-detail',
  //   component: () => import('@/views/pages/heykama/kelas/Detail.vue'),
  //   meta: {
  //     module: 'Class',
  //     pageTitle: 'Class',
  //     authRequired: true,
  //     breadcrumb: [
  //       {
  //         text: 'List',
  //         to: '/kelas',
  //       },
  //       {
  //         text: 'Detail Penerimaan',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
