export default [
  {
    path: '/mutasi',
    name: 'route-mutasi',
    component: () => import('@/views/pages/mutasi/List.vue'),
    meta: {
      pageTitle: 'Stock Transfer',
      authRequired: true,
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mutasi/add',
    name: 'route-mutasi-add',
    component: () => import('@/views/pages/mutasi/Store.vue'),
    meta: {
      parent: '/mutasi',
      pageTitle: 'Stock Transfer',
      authRequired: true,
      breadcrumb: [
        {
          text: 'List',
          to: '/mutasi',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/request-mutasi',
    name: 'route-request-mutasi',
    component: () => import('@/views/pages/mutasi/Request.vue'),
    meta: {
      pageTitle: 'Stock Transfer',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Terima Mutasi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/request-mutasi/penempatan/:id',
    name: 'route-request-mutasi-penempatan',
    component: () => import('@/views/pages/mutasi/Penyimpanan.vue'),
    meta: {
      parent: '/request-mutasi',
      pageTitle: 'Daftar Request Mutasi',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Request Mutasi',
          to: '/request-mutasi',
        },
        {
          text: 'Penempatan Barang Mutasi',
          active: true,
        },
      ],
    },
  },
  // {
  //     path: '/mutasi/edit/:id',
  //     name: 'route-mutasi-edit',
  //     component: () => import('@/views/pages/mutasi/Store.vue'),
  //     meta: {
  //         parent: '/mutasi',
  //         pageTitle: 'Edit Mutasi',
  //         authRequired: true
  //     }
  // }
]
