import service from '@/services'

// Module Vuex kas
export default {
  namespaced: true,
  state: {
    datas: [],
    currentKas: null,
    kasOptions: [],
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data
    },
    SET_CURRENT_KAS(state, data) {
      state.currentKas = data
    },
    SET_KAS_OPTION(state, data) {
      state.kasOptions = data
    },
  },
  actions: {
    async getData({ commit }, params = {}) {
      try {
        const config = {
          url: '/kas',
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getDataById({}, id) {
      try {
        const config = {
          url: `/kas/${id}`,
          method: 'get',
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async save({}, payload = {}) {
      try {
        const config = {
          url: '/input/kas',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async mutasiKas({}, payload) {
      try {
        const config = {
          url: '/kas-mutasi',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async saveJurnalKas({}, payload = {}) {
      try {
        const config = {
          url: '/input/jurnal',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}