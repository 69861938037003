export default [

  {
    path: '/disposal',
    name: 'route-disposal',
    component: () => import('@/views/pages/heykama/outbound/disposal/List.vue'),
    meta: {
      authRequired: true,
      module: 'Disposal',
      pageTitle: 'Disposal',
      breadcrumb: [
        {
          text: 'Outbound',
          to: '/outbound',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/disposal/add',
    name: 'route-disposal-add',
    component: () => import('@/views/pages/heykama/outbound/disposal/Store.vue'),
    meta: {
      authRequired: true,
      module: 'Disposal',
      pageTitle: 'Disposal',
      breadcrumb: [
        {
          text: 'Outbound',
          to: '/outbound',
        },
        {
          text: 'List',
          to: '/disposal',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/disposal/detail/:id',
  //   name: 'route-disposaldetail',
  //   component: () => import('@/views/pages/disposal/DetailList.vue'),
  //   meta: {
  //     parent: '/disposal',
  //     authRequired: true,
  //     module: 'Disposal hilang',
  //     pageTitle: 'Disposal hilang',
  //     breadcrumb: [
  //       {
  //         text: 'Disposal hilang',
  //         to: '/disposal',
  //         // active: true,
  //       },
  //       {
  //         text: 'Detail Barang',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
