import service from '@/services'

// Module Vuex kategori
export default {
  namespaced: true,
  state: {
    datas: [],
    dataLaba: [],
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data
    },
    SET_DATA_LABA(state, data) {
      state.dataLaba = data
    },
    SET_DATA_HEY_PENERIMAAN(state, data) {
      state.dataheypenerimaan = data
    },
  },
  actions: {
    async checkCompanyDebt({}, params = {}) {
      try {
        const config = {
          url: '/check-hutang',
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getGrafikBarang({}, params = {}) {
      try {
        const config = {
          url: '/dashboard-info-barang',
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getGrafikPenjualan({ commit }, params = {}) {
      try {
        const config = {
          url: '/dashboard-grafik-penjualan',
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        const result = response.data.labels && response.data.datas ? response.data : []
        commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getGrafikLabaRugi({ commit }, params = {}) {
      try {
        const config = {
          url: '/dashboard-grafik-laba-rugi',
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        const result = response.data.label && response.data.data ? response.data : []
        commit('SET_DATA_LABA', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async cardInfo({}, payload = {}) {
      try {
        const config = {
          url: '/dashboard-info',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
//HEYKAMA
    async heypenerimaan({ commit }, params = {}) {
      try {
        const config = {
          url: '/dashboard-penerimaan',
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        const result = response.data
        commit('SET_DATA_HEY_PENERIMAAN', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}
