export default [
  {
    path: '/forecast',
    name: 'route-forecast',
    component: () => import('@/views/pages/heykama/forecast/List.vue'),
    meta: {
      module: 'Forecast',
      pageTitle: 'Forecast',
      authRequired: true,
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/forecast/add',
    name: 'route-forecast-add',
    component: () => import('@/views/pages/heykama/forecast/Store.vue'),
    meta: {
      parent: '/forecast',
      module: 'Forecast',
      authRequired: true,
      pageTitle: 'Forecast',
      breadcrumb: [
        {
          text: 'List',
          to: '/forecast',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/forecast/edit/:id',
    name: 'route-forecast-edit',
    component: () => import('@/views/pages/heykama/forecast/Edit.vue'),
    meta: {
      parent: '/forecast',
      module: 'Forecast',
      authRequired: true,
      pageTitle: 'Forecast',
      breadcrumb: [
        {
          text: 'List',
          to: '/forecast',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
]
