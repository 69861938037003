import service from '@/services'

export default {
  namespaced: true,
  state: {
    datas: [],
    currentMutation: null,
  },
  getters: {
    searchById: state => id => state.datas.find(mutasi => mutasi.id == id),
    getData: state => id => state.datas.find(item => item.id == id),
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data
    },
  },
  actions: {
    async printAjuan({}, params = {}) {
      try {
        const config = {
          url: '/cetak-mutasi',
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          responseType: 'blob',
        }
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data
          const text = await blob.text()
          const errorJson = JSON.parse(text)

          return Promise.reject(errorJson)
        }
        return Promise.reject(e)
      }
    },
    async save({}, payload) {
      try {
        const config = {
          url: '/input/gudang-mutasi',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getData({ commit }, params) {
      try {
        const config = {
          method: 'get',
          url: '/gudang-mutasi',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getDataById({}, id) {
      try {
        const config = {
          method: 'get',
          url: `/gudang-mutasi/${id}`,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
        // const response = await service.sendRequest(config)
        // const result = response.data && response.data.data ? response.data.data : []
        commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async saveToko({}, payload) {
      try {
        const config = {
          url: '/mutasi-stok-toko',
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async deleteToko({}, payload) {
      try {
        const config = {
          url: '/mutasi-stok-toko',
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: 'delete',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getDataToko({ commit }, params) {
      try {
        const config = {
          method: 'get',
          url: '/mutasi-stok-toko',
          baseURL: "https://api-heykama.notive.my.id/v2",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getDataByIdToko({}, id) {
      try {
        const config = {
          method: 'get',
          url: `/mutasi-stok-toko/${id}`,
          baseURL: "https://api-heykama.notive.my.id/v2",
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
        // const response = await service.sendRequest(config)
        // const result = response.data && response.data.data ? response.data.data : []
        commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (e) {
        return Promise.reject(e)
      }
    },
  },
}
