export default [

  {
    path: '/dashboard-procurement',
    name: 'route-dashboard-procurement',
    component: () => import('@/views/pages/heykama/dashboard/procurement/Dashboard.vue'),
    meta: {
      module: 'Dashboard Procurement',
      authRequired: true,
      pageTitle: 'Dashboard Procurement',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dashboard-warehouse',
    name: 'route-dashboard-warehouse',
    component: () => import('@/views/pages/heykama/dashboard/warehouse/Dashboard.vue'),
    meta: {
      module: 'Dashboard Warehouse',
      authRequired: true,
      pageTitle: 'Dashboard Warehouse',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dashboard-product',
    name: 'route-dashboard-product',
    component: () => import('@/views/pages/heykama/dashboard/product/Dashboard.vue'),
    meta: {
      module: 'Dashboard Product',
      authRequired: true,
      pageTitle: 'Dashboard Product',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dashboard-room',
    name: 'route-dashboard-room',
    component: () => import('@/views/pages/heykama/dashboard/room/Dashboard.vue'),
    meta: {
      module: 'Dashboard Room',
      authRequired: true,
      pageTitle: 'Dashboard Room',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
]
