import QueryString from 'qs'

export const setDefaultModule = (service, data) => ({
  namespaced: true,
  state: {
    datas: [],
    status: [],
    total: 0,
  },
  getters: {
    search(state) {
      return id => state.datas.find(bop => bop.id == id)
    },
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data
    },
    SET_STATUS(state, data) {
      state.status = data
    },
    SET_TOTAL_DATA(state, data) {
      state.total = data
    },
  },
  actions: {
    async getData({ commit }, params = {}) {
      try {
        const config = {
          url: data.url,
          method: 'get',
          params,
          paramsSerializer: params => QueryString.stringify(params),
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },

        }
        const response = await service.sendRequest(config)

        const result = response.data && response.data.data ? response.data.data : []
        const total = response.data ? response.data.total : result.length
        commit('SET_TOTAL_DATA', total)
        commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getDataById({}, id) {
      try {
        const config = {
          url: `${data.url}/${id}`,
          method: 'get',
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },

        }
        const response = await service.sendRequest(config)

        const result = response.data && response.data ? response.data : []
        // commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async save({}, payload = {}) {
      try {
        const config = {
          url: `/input${data.url}`,
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async deleterekinsup({}, payload = {}) {
      try {
        const config = {
          url: `/supplier-rekening/delete`,
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
})
